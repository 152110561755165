// Dependencies
import React from "react"
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
// ...

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
